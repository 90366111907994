import { type LoaderFunctionArgs } from '@remix-run/node';
import { type MetaFunction, useLoaderData } from '@remix-run/react';
import chunk from 'lodash/chunk';
import shuffle from 'lodash/shuffle';
import { Fragment } from 'react';
import { $path } from 'remix-routes';

import { EnumsPageName } from '@lp-lib/api-service-client/public';

import { useAnonAppAnalytics } from '../../src/analytics/app/anon';
import { PageManagerUtils } from '../../src/components/PageManager/utils';
import config from '../../src/config';
import { type Tag } from '../../src/types';
import { type GamePack } from '../../src/types/game';
import { fromDTOGamePack } from '../../src/utils/api-dto';
import { getGridStyle } from '../../src/utils/css';
import { GamePackBreadcrumbs } from '../components/GamePack/GamePackBreadcrumbs';
import {
  CategorizedGamePacks,
  LinkableAnonGamePackCard,
  useHydratedNumPerRow,
} from '../components/GamePack/GamePackCollection';
import { GamePackTagBanner } from '../components/GamePack/GamePackTagBanner';
import {
  makeAnonGamePackTagUrl,
  makeAnonPackUrl,
} from '../components/GamePack/utils';
import { GetUnlimitedAccessButton } from '../components/GetUnlimitedAccessButton';
import { MetadataUtils } from '../components/metadata';
import { AnonPublicLibraryLayout } from '../components/PublicLibrary/AnonPublicLibraryLayout';
import { AnonPublicLibraryNav } from '../components/PublicLibrary/AnonPublicLibraryNav';
import {
  fetchGamePacksByTag,
  fetchGamePacksByTags,
} from '../fetches/fetchGamePacksByTags';
import { fetchPage } from '../fetches/fetchPage';
import { fetchTagBySlug } from '../fetches/fetchTag';
import { useExploreData } from './explore';

export const loader = async (action: LoaderFunctionArgs) => {
  const slug = action.params.slug;
  if (!slug) throw new Response('Not Found', { status: 404 });

  const primaryTag = await fetchTagBySlug(slug);
  const primaryGamePacks = await fetchGamePacksByTag(primaryTag.id, 200);

  const page = await fetchPage(EnumsPageName.PageNameExplore);
  const featuredTags = PageManagerUtils.GetGamePackTags(page);
  const groupedSecondaryGamePacks = await fetchGamePacksByTags(
    featuredTags.filter((t) => t.id !== primaryTag.id).map((t) => t.id)
  );
  const secondaryTags = shuffle(
    featuredTags.filter((t) => t.id !== primaryTag.id)
  );

  return {
    primaryTag,
    featuredTags,
    secondaryTags,
    primaryGamePacks,
    groupedSecondaryGamePacks,
  };
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (!data?.primaryTag) return [];

  return MetadataUtils.Make({
    title: data?.primaryTag.name ?? 'Explore Library',
    desc: data?.primaryTag.description ?? '',
    url: new URL(makeAnonGamePackTagUrl(data?.primaryTag), config.app.baseUrl)
      .href,
    image: 'generic',
  });
};

export default function Component() {
  const exploreData = useExploreData();

  const {
    primaryTag,
    secondaryTags,
    primaryGamePacks,
    groupedSecondaryGamePacks,
  } = useLoaderData<typeof loader>();

  const onGamePackClick = (
    p: GamePack,
    index: number,
    section: string,
    tag: Tag,
    tagIndex: number
  ) => {
    analytics.trackLibraryGamePackClicked({
      path: window.location.pathname,
      from: 'category page',
      section,

      packId: p.id,
      packName: p.name,
      packIndex: index,
      categoryId: tag.id,
      categoryName: tag.name,
      categoryIndex: tagIndex,
    });
    window.open(
      makeAnonPackUrl(p, {
        tag,
      }),
      '_blank'
    );
  };

  const numPerRow = useHydratedNumPerRow();
  const analytics = useAnonAppAnalytics();

  return (
    <AnonPublicLibraryLayout
      {...exploreData}
      secondaryNav={
        <AnonPublicLibraryNav
          pageName={exploreData.pageName}
          page={exploreData.page}
          isSectionSelected={(section) =>
            !!section.rows?.find((row) => row.id === String(primaryTag.id))
          }
        />
      }
      contentKey={`explore-tags-${primaryTag.id}`}
    >
      <div
        className='
            w-full flex-1 text-white 
            flex flex-col
          '
        key={primaryTag.id}
      >
        <GamePackTagBanner
          tag={primaryTag}
          breadcrumb={
            <GamePackBreadcrumbs
              items={[
                {
                  label: 'Explore',
                  to: $path('/explore'),
                },
                {
                  label: primaryTag.name,
                },
              ]}
            />
          }
        />

        <div className='px-10'>
          <div className='flex justify-between items-center'>
            <p className={`text-2xl font-bold`}>{primaryTag.name}</p>
          </div>

          <div
            className={`mt-6 grid gap-y-12 gap-x-3 pt-4 ${
              numPerRow > 0 ? 'visible' : 'invisible'
            }`}
            style={{ gridTemplateColumns: getGridStyle(numPerRow) }}
          >
            {primaryGamePacks.map((p, index) => (
              <LinkableAnonGamePackCard
                key={p.id}
                pack={fromDTOGamePack(p)}
                onClick={(pack) =>
                  onGamePackClick(pack, index, 'primary', primaryTag, 0)
                }
              />
            ))}
          </div>
        </div>

        <div className='my-20 px-10'>
          <div className='w-full flex flex-col items-center'>
            <header className='text-3.5xl font-bold font-Montserrat'>
              Other Experiences
            </header>
            <main className='mt-10 w-full flex flex-col gap-15'>
              {chunk(secondaryTags, 3).map((tags, i) => (
                <Fragment key={i}>
                  {tags.map((tag, tagIndex) => (
                    <CategorizedGamePacks
                      key={tag.id}
                      tag={tag}
                      packs={groupedSecondaryGamePacks[tag.id] ?? []}
                      viewAll={(tag) =>
                        $path('/explore/tags/:slug', { slug: tag.slug })
                      }
                      onViewAllClick={(tag) =>
                        analytics.trackLibraryCategoryClicked(tag.name)
                      }
                      onClickGamePack={(pack, index) =>
                        onGamePackClick(
                          pack,
                          index,
                          'other-experiences',
                          tag,
                          tagIndex
                        )
                      }
                    />
                  ))}
                  {tags.length === 3 && (
                    <div className='flex items-center justify-center'>
                      <GetUnlimitedAccessButton className='w-75 h-15 text-base' />
                    </div>
                  )}
                </Fragment>
              ))}
            </main>
          </div>
        </div>
      </div>
    </AnonPublicLibraryLayout>
  );
}
