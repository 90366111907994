import { Link } from '@remix-run/react';

import config from '../../src/config';

export function GetUnlimitedAccessButton(props: { className?: string }) {
  return (
    <Link
      to={`${config.app.marketHomeUrl}/pricing`}
      className={`btn-delete rounded flex items-center justify-center font-semibold font-Montserrat w-50 h-10 ${
        props.className ?? ''
      }`}
      target='_blank'
    >
      👉 Get Unlimited Access
    </Link>
  );
}
